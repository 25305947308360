<template>
    <div class="section">
        <navigation></navigation>
        <div class="sec-title text-center mt-10"><h2>Grower's Profile</h2></div>
        <div class="sec-treeprof">
            <v-row class="mt-3 px-8">
                <v-col cols="12" md="2">
                    <div class="d-flex justify-center">
                        <v-img
                            alt="La Finca Tree"
                            class="shrink tree-img"
                            contain
                            src="../assets/default-photo.jpg"
                            transition="scale-transition"
                            width="220"
                            height="220"
                        />
                    </div>
                    
                </v-col>
                <v-col cols="12" md="10">
                    <div class="row">
                        <div class="col-12-md mt-8 personal_info">
                            <h3>Juan Dela Cruz</h3>
                            <div>Address: Sipocot, Camarines Sur</div>
                            <div>Contact Number: 01234567891</div>

                            <h4 class="mt-5">Professional Summary</h4>
                            <div class="summary-desc">Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum quaerat vel commodi molestias dolores quae voluptatum asperiores accusantium placeat ipsam officiis aliquam eaque maxime veritatis, numquam quibusdam. Vitae, quasi ea.</div> 
                        </div>  

                        
                                        
                    </div>
            
                </v-col>   
            </v-row>

            <v-row class="px-8">
                <v-col cols="12" md="12" class="summary">
                    <h4>Highlights</h4>
                    <div class="summary-desc"></div>

                    <ul class="list">
                        <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</li>
                        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit.</li>
                    </ul>
                </v-col>
            </v-row>

            
        </div>
    </div>
</template>

<script>
import Navigation from './Navigation.vue'
export default {
  components: { Navigation },

}
</script>

<style>
 .section{padding: 5px 65px 5px 65px;}
    .sec-treeprof{
        margin-top: 20px;
        
        text-align: justify;
    }
    .treeprof-title{
        font-size: 25px;
        font-weight: bold;
    }
    .variety{
        font-size: 20px;
        font-weight: bold;
    }

    @media only screen and (max-width: 600px) {
      .section{
        padding: 0px;
      }
      .personal_info, .summary{
        text-align: center;
      }
      .list{list-style-type:none;}
    }
</style>